import React from 'react';

export default function App() {
  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '20px auto' }}>
      <h1>Privacy Policy for Iweydii</h1>
      <p>**Last Updated**: October 6, 2024</p>

      <h2>1. Introduction</h2>
      <p>
        Welcome to **Iweydii**! Your privacy is critically important to us. This Privacy Policy explains how we collect, use, and protect your personal information when you use our local classified ads platform for buying and selling items.
      </p>

      <h2>2. Information We Collect</h2>
      <p>
        We collect the following types of information to provide you with the best experience:
      </p>
      <ul>
        <li><b>Personal Information:</b> Name, Email Address, and Phone Number for account creation or support inquiries.</li>
        <li><b>Listing and Transaction Information:</b> Details about items you list for sale, including images, descriptions, and prices.</li>
        <li><b>Device and Usage Information:</b> Information such as IP address, device type, and usage statistics.</li>
      </ul>

      <h2>3. How We Use Your Information</h2>
      <p>
        We use your information to facilitate buying and selling interactions, communicate with you, and improve our services.
      </p>

      <h2>4. Sharing Your Information</h2>
      <p>
        We do not share your personal information with any third-party companies, except when required by law or to protect the safety of our users.
      </p>

      <h2>5. Security of Your Information</h2>
      <p>
        We implement appropriate security measures to safeguard your data. However, no transmission over the internet is completely secure, and we cannot guarantee absolute protection.
      </p>

      <h2>6. Your Choices and Controls</h2>
      <p>
        You can control and manage your information in the following ways:
      </p>
      <ul>
        <li><b>Access and Update:</b> You can update your profile information through your account settings.</li>
        <li><b>Opt-Out:</b> You can opt out of receiving marketing communications by following the unsubscribe instructions in our emails.</li>
        <li><b>Delete Account:</b> You can request to delete your account by contacting us at support@iweydii.com.</li>
      </ul>

      <h2>7. Children’s Privacy</h2>
      <p>
        Our services are not intended for children under 13 years of age. We do not knowingly collect personal information from children. If you believe we have collected such information, please contact us, and we will take appropriate action.
      </p>

      <h2>8. Third-Party Links</h2>
      <p>
        Our app may contain links to third-party websites or services. We are not responsible for the privacy practices of these third parties. Please review their privacy policies before using their services.
      </p>

      <h2>9. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. If we make significant changes, we will notify you through the app or by email. Please review this policy periodically to stay informed.
      </p>

      <h2>10. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy, please contact us at: <b>support@iweydii.com</b>.
      </p>
    </div>
  );
}
